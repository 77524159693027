import React from "react";

const Trend = () => (
  <svg
    width="89"
    height="23"
    viewBox="0 0 89 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.692 19.572L7.16 20.076H8.924V22.236H6.728C6.44 22.236 6.188 22.128 5.972 21.912L4.856 20.796C4.64 20.58 4.532 20.328 4.532 20.04V9.06H0.104V6.864H4.532V0.275999H6.692V6.864H11.12V9.06H6.692V19.572ZM21.2768 12.732V22.2L19.1168 22.236L19.1528 10.176C19.1528 9.888 19.0448 9.624 18.8288 9.384C18.5888 9.168 18.3128 9.06 18.0008 9.06H17.3888L17.3528 6.9L19.1168 6.864C19.4768 6.864 19.9208 7.104 20.4488 7.584C20.9768 8.064 21.2528 8.568 21.2768 9.096V9.744L23.7608 7.224C23.9768 7.008 24.2288 6.9 24.5168 6.9L27.1448 6.864C27.4568 6.864 27.7088 6.972 27.9008 7.188L30.1688 9.384C30.3848 9.6 30.4928 9.864 30.4928 10.176V11.256H28.3328V10.608L26.7128 9.06H24.9848L21.2768 12.732ZM38.7776 15.576V18.528L40.3256 20.148H49.7936V22.164H39.8936C39.6056 22.164 39.3656 22.068 39.1736 21.876L36.9416 19.716C36.7496 19.524 36.6536 19.272 36.6536 18.96V10.248C36.6536 10.008 36.7736 9.768 37.0136 9.528L39.2096 7.26C39.4736 6.996 39.7376 6.864 40.0016 6.864H46.5176C46.7576 6.864 47.0096 6.996 47.2736 7.26L49.3976 9.492C49.6136 9.708 49.7216 9.948 49.7216 10.212V15.576H38.7776ZM38.8136 13.344H47.6336V10.644L46.0856 9.06H40.3976L38.8136 10.68V13.344ZM59.8423 8.088L60.7063 7.224C60.9223 7.008 61.1743 6.9 61.4623 6.9H65.9263C66.2143 6.9 66.4543 6.996 66.6463 7.188L68.7703 9.276C68.9863 9.492 69.0943 9.744 69.0943 10.032V22.164H67.0423V10.464L65.4943 8.988H61.8943L60.3823 10.572V22.2H58.2223V10.14C58.2223 10.092 58.2703 9.948 58.3663 9.708L57.7183 8.988H55.9543V6.864H58.2223C58.5103 6.864 58.7383 6.972 58.9063 7.188L59.8423 8.088ZM88.3951 22.164H80.7631C80.4511 22.164 80.1991 22.068 80.0071 21.876L75.5791 17.52C75.3631 17.304 75.2551 17.052 75.2551 16.764V12.336C75.2551 12.048 75.3631 11.796 75.5791 11.58L79.8991 7.224C80.1151 7.008 80.3551 6.9 80.6191 6.9H86.2351V0.275999H88.3951V22.164ZM86.2351 20.076V9.024H81.0871L77.3791 12.768V16.332L81.1951 20.076H86.2351Z"
      fill="#6A7A82"
    />
  </svg>
);

export default Trend;
