import React from "react";

const Ourthreedots = () => (
  <svg
    width="180"
    height="78"
    viewBox="0 0 180 78"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M138.318 34.7352C138.318 31.6136 140.651 29.083 143.528 29.083H174.79C177.667 29.083 180 31.6136 180 34.7352V48.917H143.528C140.651 48.917 138.318 46.3864 138.318 43.2648V34.7352Z"
      fill="#6A7A82"
    />
    <path
      d="M113.309 63.8182C113.309 60.6966 115.642 58.166 118.519 58.166H149.781C152.658 58.166 154.991 60.6966 154.991 63.8182V72.3478C154.991 75.4694 152.658 78 149.781 78H113.309V63.8182Z"
      fill="#6A7A82"
    />
    <path
      d="M113.309 5.65218C113.309 2.53057 115.642 0 118.519 0H149.781C152.658 0 154.991 2.53056 154.991 5.65217V14.1818C154.991 17.3034 152.658 19.834 149.781 19.834H113.309V5.65218Z"
      fill="#6A7A82"
    />
    <path
      d="M5.19671 46.1829C4.2128 46.1829 3.32589 45.9611 2.53599 45.5173C1.75995 45.0597 1.14328 44.4288 0.685965 43.6245C0.228655 42.8064 0 41.8565 0 40.7749C0 39.6933 0.228655 38.7504 0.685965 37.9461C1.15713 37.128 1.78767 36.4971 2.57757 36.0533C3.36746 35.5957 4.24744 35.3669 5.21749 35.3669C6.2014 35.3669 7.08138 35.5957 7.85742 36.0533C8.64732 36.4971 9.27092 37.128 9.72823 37.9461C10.1994 38.7504 10.435 39.6933 10.435 40.7749C10.435 41.8565 10.1994 42.8064 9.72823 43.6245C9.27092 44.4288 8.64732 45.0597 7.85742 45.5173C7.06752 45.9611 6.18061 46.1829 5.19671 46.1829ZM5.19671 44.2901C5.7233 44.2901 6.20833 44.1584 6.65178 43.8949C7.10909 43.6315 7.47633 43.2432 7.75348 42.7301C8.03064 42.2032 8.16922 41.5515 8.16922 40.7749C8.16922 39.9984 8.03064 39.3536 7.75348 38.8405C7.49018 38.3136 7.12988 37.9184 6.67257 37.6549C6.22912 37.3915 5.74409 37.2597 5.21749 37.2597C4.69089 37.2597 4.19894 37.3915 3.74163 37.6549C3.29818 37.9184 2.93787 38.3136 2.66071 38.8405C2.38356 39.3536 2.24498 39.9984 2.24498 40.7749C2.24498 41.5515 2.38356 42.2032 2.66071 42.7301C2.93787 43.2432 3.29818 43.6315 3.74163 43.8949C4.18508 44.1584 4.67011 44.2901 5.19671 44.2901Z"
      fill="#6A7A82"
    />
    <path
      d="M22.2966 35.6165V45.9333H20.3427L20.1764 44.1237C19.8576 44.7616 19.3865 45.2677 18.7629 45.6421C18.1531 46.0027 17.4464 46.1829 16.6426 46.1829C15.3954 46.1829 14.4115 45.7947 13.6909 45.0181C12.9841 44.2416 12.6308 43.0837 12.6308 41.5445V35.6165H14.8134V41.3157C14.8134 43.3125 15.631 44.3109 17.2662 44.3109C18.0838 44.3109 18.7559 44.0197 19.2825 43.4373C19.823 42.8549 20.0932 42.0229 20.0932 40.9413V35.6165H22.2966Z"
      fill="#6A7A82"
    />
    <path
      d="M25.0788 45.9333V35.6165H27.0536L27.2406 37.5717C27.6009 36.8923 28.0998 36.3584 28.7373 35.9701C29.3886 35.568 30.1716 35.3669 31.0862 35.3669V37.6757H30.4834C29.8736 37.6757 29.3263 37.7797 28.8412 37.9877C28.3701 38.1819 27.989 38.5216 27.6979 39.0069C27.4208 39.4784 27.2822 40.1371 27.2822 40.9829V45.9333H25.0788Z"
      fill="#6A7A82"
    />
    <path
      d="M37.299 45.9333C36.2874 45.9333 35.4836 45.6907 34.8878 45.2053C34.2919 44.7061 33.9939 43.8256 33.9939 42.5637V37.4677H32.227V35.6165H33.9939L34.2642 32.9957H36.1973V35.6165H39.1075V37.4677H36.1973V42.5637C36.1973 43.1323 36.3151 43.5275 36.5507 43.7493C36.8001 43.9573 37.2228 44.0613 37.8187 44.0613H39.0035V45.9333H37.299Z"
      fill="#6A7A82"
    />
    <path
      d="M41.3794 45.9333V30.9573H43.5828V37.2805C43.9293 36.6843 44.4074 36.2197 45.0171 35.8869C45.6407 35.5403 46.3267 35.3669 47.075 35.3669C48.3084 35.3669 49.2784 35.7552 49.9852 36.5317C50.6919 37.3083 51.0453 38.4661 51.0453 40.0053V45.9333H48.8627V40.2341C48.8627 38.2373 48.0658 37.2389 46.4722 37.2389C45.6407 37.2389 44.9478 37.5301 44.3935 38.1125C43.853 38.6949 43.5828 39.5269 43.5828 40.6085V45.9333H41.3794Z"
      fill="#6A7A82"
    />
    <path
      d="M53.6404 45.9333V35.6165H55.6151L55.8022 37.5717C56.1625 36.8923 56.6614 36.3584 57.2989 35.9701C57.9502 35.568 58.7332 35.3669 59.6478 35.3669V37.6757H59.045C58.4352 37.6757 57.8878 37.7797 57.4028 37.9877C56.9316 38.1819 56.5505 38.5216 56.2595 39.0069C55.9824 39.4784 55.8438 40.1371 55.8438 40.9829V45.9333H53.6404Z"
      fill="#6A7A82"
    />
    <path
      d="M66.0753 46.1829C65.0637 46.1829 64.1629 45.9611 63.373 45.5173C62.597 45.0597 61.9872 44.4288 61.5438 43.6245C61.1003 42.8203 60.8786 41.8843 60.8786 40.8165C60.8786 39.7349 61.0934 38.7851 61.523 37.9669C61.9664 37.1488 62.5762 36.5109 63.3522 36.0533C64.1421 35.5957 65.0567 35.3669 66.0961 35.3669C67.1077 35.3669 67.9877 35.5957 68.736 36.0533C69.4843 36.4971 70.0664 37.0933 70.4821 37.8421C70.8978 38.5909 71.1057 39.416 71.1057 40.3173C71.1057 40.456 71.0988 40.6085 71.0849 40.7749C71.0849 40.9275 71.078 41.1008 71.0641 41.2949H63.0404C63.1097 42.2933 63.4354 43.056 64.0174 43.5829C64.6133 44.096 65.2992 44.3525 66.0753 44.3525C66.6989 44.3525 67.2186 44.2139 67.6343 43.9365C68.0639 43.6453 68.3826 43.2571 68.5905 42.7717H70.7939C70.5167 43.7424 69.9624 44.5536 69.131 45.2053C68.3133 45.8571 67.2948 46.1829 66.0753 46.1829ZM66.0753 37.1765C65.3408 37.1765 64.6895 37.3984 64.1213 37.8421C63.5532 38.272 63.2067 38.9237 63.082 39.7973H68.9023C68.8607 38.9931 68.5766 38.3552 68.05 37.8837C67.5234 37.4123 66.8652 37.1765 66.0753 37.1765Z"
      fill="#6A7A82"
    />
    <path
      d="M78.2145 46.1829C77.2028 46.1829 76.3021 45.9611 75.5122 45.5173C74.7361 45.0597 74.1264 44.4288 73.6829 43.6245C73.2395 42.8203 73.0178 41.8843 73.0178 40.8165C73.0178 39.7349 73.2326 38.7851 73.6622 37.9669C74.1056 37.1488 74.7154 36.5109 75.4914 36.0533C76.2813 35.5957 77.1959 35.3669 78.2353 35.3669C79.2469 35.3669 80.1269 35.5957 80.8752 36.0533C81.6235 36.4971 82.2055 37.0933 82.6213 37.8421C83.037 38.5909 83.2449 39.416 83.2449 40.3173C83.2449 40.456 83.238 40.6085 83.2241 40.7749C83.2241 40.9275 83.2172 41.1008 83.2033 41.2949H75.1796C75.2489 42.2933 75.5745 43.056 76.1566 43.5829C76.7525 44.096 77.4384 44.3525 78.2145 44.3525C78.8381 44.3525 79.3577 44.2139 79.7735 43.9365C80.2031 43.6453 80.5218 43.2571 80.7297 42.7717H82.9331C82.6559 43.7424 82.1016 44.5536 81.2701 45.2053C80.4525 45.8571 79.434 46.1829 78.2145 46.1829ZM78.2145 37.1765C77.48 37.1765 76.8287 37.3984 76.2605 37.8421C75.6923 38.272 75.3459 38.9237 75.2212 39.7973H81.0415C80.9999 38.9931 80.7158 38.3552 80.1892 37.8837C79.6626 37.4123 79.0044 37.1765 78.2145 37.1765Z"
      fill="#6A7A82"
    />
    <path
      d="M90.2289 46.1829C89.2312 46.1829 88.3512 45.9472 87.589 45.4757C86.8268 45.0043 86.2309 44.3595 85.8013 43.5413C85.3717 42.7232 85.1569 41.7941 85.1569 40.7541C85.1569 39.7141 85.3717 38.792 85.8013 37.9877C86.2309 37.1696 86.8268 36.5317 87.589 36.0741C88.365 35.6027 89.2519 35.3669 90.2497 35.3669C91.0673 35.3669 91.781 35.5264 92.3908 35.8453C93.0144 36.1643 93.4994 36.6149 93.8458 37.1973V30.9573H96.0492V45.9333H94.0745L93.8458 44.3317C93.5132 44.8171 93.0559 45.2469 92.4739 45.6213C91.8919 45.9957 91.1435 46.1829 90.2289 46.1829ZM90.6239 44.2693C91.5662 44.2693 92.3353 43.9435 92.9312 43.2917C93.541 42.64 93.8458 41.8011 93.8458 40.7749C93.8458 39.7349 93.541 38.896 92.9312 38.2581C92.3353 37.6064 91.5662 37.2805 90.6239 37.2805C89.6815 37.2805 88.9055 37.6064 88.2958 38.2581C87.686 38.896 87.3811 39.7349 87.3811 40.7749C87.3811 41.4544 87.5197 42.0576 87.7969 42.5845C88.074 43.1115 88.4551 43.5275 88.9401 43.8325C89.439 44.1237 90.0003 44.2693 90.6239 44.2693Z"
      fill="#6A7A82"
    />
    <path
      d="M103.65 46.1829C102.666 46.1829 101.779 45.9611 100.989 45.5173C100.213 45.0597 99.5965 44.4288 99.1392 43.6245C98.6819 42.8064 98.4532 41.8565 98.4532 40.7749C98.4532 39.6933 98.6819 38.7504 99.1392 37.9461C99.6103 37.128 100.241 36.4971 101.031 36.0533C101.821 35.5957 102.701 35.3669 103.671 35.3669C104.655 35.3669 105.535 35.5957 106.311 36.0533C107.101 36.4971 107.724 37.128 108.181 37.9461C108.653 38.7504 108.888 39.6933 108.888 40.7749C108.888 41.8565 108.653 42.8064 108.181 43.6245C107.724 44.4288 107.101 45.0597 106.311 45.5173C105.521 45.9611 104.634 46.1829 103.65 46.1829ZM103.65 44.2901C104.177 44.2901 104.662 44.1584 105.105 43.8949C105.562 43.6315 105.93 43.2432 106.207 42.7301C106.484 42.2032 106.622 41.5515 106.622 40.7749C106.622 39.9984 106.484 39.3536 106.207 38.8405C105.943 38.3136 105.583 37.9184 105.126 37.6549C104.682 37.3915 104.197 37.2597 103.671 37.2597C103.144 37.2597 102.652 37.3915 102.195 37.6549C101.751 37.9184 101.391 38.3136 101.114 38.8405C100.837 39.3536 100.698 39.9984 100.698 40.7749C100.698 41.5515 100.837 42.2032 101.114 42.7301C101.391 43.2432 101.751 43.6315 102.195 43.8949C102.638 44.1584 103.123 44.2901 103.65 44.2901Z"
      fill="#6A7A82"
    />
    <path
      d="M115.189 45.9333C114.177 45.9333 113.373 45.6907 112.777 45.2053C112.182 44.7061 111.884 43.8256 111.884 42.5637V37.4677H110.117V35.6165H111.884L112.154 32.9957H114.087V35.6165H116.997V37.4677H114.087V42.5637C114.087 43.1323 114.205 43.5275 114.44 43.7493C114.69 43.9573 115.112 44.0613 115.708 44.0613H116.893V45.9333H115.189Z"
      fill="#6A7A82"
    />
    <path
      d="M123.385 46.1829C122.082 46.1829 121.008 45.864 120.163 45.2261C119.318 44.5883 118.833 43.7424 118.708 42.6885H120.932C121.043 43.16 121.306 43.5691 121.722 43.9157C122.138 44.2485 122.685 44.4149 123.364 44.4149C124.029 44.4149 124.514 44.2763 124.819 43.9989C125.124 43.7216 125.276 43.4027 125.276 43.0421C125.276 42.5152 125.062 42.1616 124.632 41.9813C124.216 41.7872 123.634 41.6139 122.886 41.4613C122.304 41.3365 121.722 41.1701 121.14 40.9621C120.572 40.7541 120.094 40.4629 119.706 40.0885C119.331 39.7003 119.144 39.1803 119.144 38.5285C119.144 37.6272 119.491 36.8784 120.184 36.2821C120.877 35.672 121.847 35.3669 123.094 35.3669C124.244 35.3669 125.173 35.6443 125.879 36.1989C126.6 36.7536 127.023 37.5371 127.147 38.5493H125.027C124.958 38.1056 124.75 37.7589 124.403 37.5093C124.071 37.2597 123.62 37.1349 123.052 37.1349C122.498 37.1349 122.068 37.2528 121.764 37.4885C121.459 37.7104 121.306 38.0016 121.306 38.3621C121.306 38.7227 121.514 39.0069 121.93 39.2149C122.359 39.4229 122.921 39.6101 123.614 39.7765C124.306 39.9291 124.944 40.1093 125.526 40.3173C126.122 40.5115 126.6 40.8027 126.96 41.1909C127.321 41.5792 127.501 42.1477 127.501 42.8965C127.515 43.8395 127.147 44.6229 126.399 45.2469C125.664 45.8709 124.66 46.1829 123.385 46.1829Z"
      fill="#6A7A82"
    />
  </svg>
);

export default Ourthreedots;
